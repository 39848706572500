<template>
    <el-dialog
      title="Dodaj Html"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose"
    >
      <el-input type="textarea" v-model="local_content" placeholder="Html" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose()">Zamknij</el-button>
        <el-button type="primary" @click="saveHtml()">Zapisz</el-button>
      </span>
    </el-dialog>
  </template>
  
  <script>
  
  export default {
    name: 'AddHtmlDialog',
    props: {
      dialogVisible: {
        type: Boolean,
        default() {
          return false;
        },
      },
    },
    data: () => {
      return {
        local_content: '',
      };
    },
    methods: {
      handleClose() {
        this.$emit('close', false);
      },
      saveHtml(){
        this.$emit('dialogContentChanged', this.local_content)
        this.$emit('close', false);
      }
    },
  };
  </script>
  
  <style scoped>

  </style>
  